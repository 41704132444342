import React from "react";
import {
  graphql,
  // Link
} from "gatsby";

// Layout
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Matrix from "../components/Matrix";
import { buildFluidImageData } from "@imgix/gatsby";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
// import { faEdit } from '@fortawesome/pro-light-svg-icons'

// Tailwind React UI Components
import { Container, Section } from "tailwind-react-ui";

// Custom Components
import PageBanner from "../components/PageBanner";

// GraphQL
export const query = graphql`
  query talkToUsQuery {
    entry: craftTalkToUsTalkToUsEntry {
      id
      remoteId
      subtitle
      title
      typeHandle
      uri
      url
      featureImage {
        ...UploadAssetFragment
      }
      metaTitle
      metaDescription
      metaKeywords
      metaImage {
        ...UploadAssetFragment
      }
      bodyContent {
        ...BodyContentFragment
      }
    }
    siteInfo: craftSiteInformationGlobalSet {
      ...SiteInfoFragment
    }
  }
`;

const truncateString = (string = "", maxLength = 54) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

const TalkToUs = ({ data: { entry, siteInfo } }) => {
  const subtitle = entry.subtitle || siteInfo.siteTitle;
  const [siteImage] = siteInfo.siteImage;
  const [featureImage = siteImage] = entry.featureImage;
  const metaImage = featureImage || entry.metaImage || siteInfo.siteImage;
  const metaDescription = truncateString(
    (
      entry.metaDescription ||
      entry.summary ||
      siteInfo.siteDescription
    ).replace(/(<([^>]+)>)/gi, ""),
    159
  );
  const metaTitle = truncateString(
    `${entry.metaTitle || entry.displayTitle || entry.title}`,
    53
  );
  const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null;

  const metaImageFormatted = buildFluidImageData(`${metaImage.url}`, {
    ar: 1, // required
    auto: ["format", "compress"],
    sizes: `300px, 300px`, // optional, but highly recommended
    width: 300,
    height: 300,
  });

  return (
    <Layout location={entry.url} title={metaTitle}>
      <Seo
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        image={metaImageFormatted.src}
        url={entry.url}
        pathname={`/${entry.uri}`}
      />
      <h1 style={{ fontSize: 0, color: "transparent" }} className="sr-only">
        {metaTitle}
      </h1>

      {featureImage && (
        <Section p="0" className="relative">
          <PageBanner
            title={entry.title}
            subtitle={subtitle}
            image={featureImage}
          />
        </Section>
      )}

      {entry.bodyContent.length ? (
        <Section p="0" className="secondary-page relative">
          <Container className="xl:w-2/3 lg:pt-20 py-14">
            <Matrix blocks={entry.bodyContent} />
          </Container>
        </Section>
      ) : (
        ""
      )}
      <Section p="0">
        <iframe
          title="Table Mountain Aerial Cableway location map"
          width="100%"
          height="420"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Table Mountain Cableway&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </Section>
    </Layout>
  );
};

export default TalkToUs;
